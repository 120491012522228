/* Remove borders from the ReactQuill editor */
.ql-container {
    border: none !important;
    border-radius: 0px !important;
}

.ql-toolbar {
    border: none !important;
    border-radius: 0px !important;
}

.ql-editor {
    border: none !important;
    border-radius: 0px !important;
    outline: none; /* Remove focus outline */
    padding: 10px; /* Add some spacing for better aesthetics */
    background-color: #f8f9fa; /* Optional: Set background color */
}

.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.react-grid-placeholder {
    background: blue;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  
  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
  
  .react-grid-item:not(.react-grid-placeholder) {
    background: grey;
  }
  
  .window-header {
    background-color: lightblue;
    display: flex;
    justify-content: space-between;
    height: 25px;
  }
  
  .logo-container {
    overflow: hidden;
  }
  
  .app-name {
    color: black;
    font-size: 14px;
    margin-bottom: 5px;
    padding-left: 8px;
  }
  
  .actions-container {
    display: flex;
    padding-top: 3px;
  }
  
  .icon {
    color: black;
    font-size: 20px;
    padding-top: 5px;
  }
  
  .window-button-style {
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    transition: background-color 150ms ease;
  }
  
  .window-controls-icon {
    pointer-events: none;
    user-select: none;
    width: 20px;
    height: 20px;
    transition: fill 150ms ease;
  }
  
  .settings-window:hover {
    background-color: orange;
  }
  
  .close-window:hover {
    background-color: #e6004c;
  }
  
  .settings-window:focus {
    outline: none;
  }
  
  .close-window:focus {
    outline: none;
  }
  