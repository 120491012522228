.app-container {
  display: flex;
  height: 100vh;
  position: relative;
  /* background-color: #2c3e50; */
  background-color: #ffffff;
  overflow-y: hidden;
}

.sidebar {
  width: 350px;
  height: 100%;
  min-height: 100vh;
  /* background-color:  rgb(46 100 184); */
  background-color: #ffffff;
  color: #ecf0f1;
  transition: width 0.3s ease;
  overflow-y: auto;
  position: relative;
  z-index: 3;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border-right: 1px dashed lightgrey;
}

.sidebar.closed {
  width: 60px;
}

.menu-icon {
  font-size: 1.5rem;
  padding: 20px;
  cursor: pointer;
  background-color: #34495e;
  position: relative;
}

.menu-icon.center {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.menu-icon.right {
  text-align: right;
  position: absolute;
  right: 10px;
}

.category-list {
  padding: 10px 20px;
  margin-top: 50px;
}

.category {
  /* margin-bottom: 10px; */
}

.category-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 350;
  padding: 10px 0;
  /* border-bottom: 1px solid #7f8c8d; */
  color: #000;

  /* color: #FFFFFF; */
  transition: color 0.2s ease;
}

.category-title:hover {
  color: #3498db;
}

.expand-icon {
  margin-right: 10px;
  font-size: 1rem;
}

/* Transition for expanding and collapsing the items list */
.items-list-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.items-list-wrapper.expanded {
  max-height: 500px; /* Adjust max-height based on your content */
}

.items-list {
  list-style: none;
  padding-left: 40px;
  margin-top: 10px;
}

.item {
  padding: 10px 0;
  cursor: pointer;
  color: #bdc3c7;
  /* border-bottom: 1px solid lightgray; */
}

.item:hover {
  color: #3498db;
}

.ppt-viewer {
  flex: 1;
  background-color: #ecf0f1;
  padding: 20px;
  overflow-y: auto;
}

.SlidePanel {
  background-color: white !important;
}

/* a {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
}

a:hover {
  background-color: #ddd;
  color: black;
} */

.previous {
  background-color: #f1f1f1;
  color: black;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 100px; */
  /* z-index: 0; */
  width: 30px;
  height: 30px;
  font-size: 30px;
}

.next {
  /* position: absolute; */
  /* top: 50%; */
  /* right: 100px; */
  /* z-index: 0; */
  width: 30px;
  height: 30px;
  font-size: 30px;
}

.round {
  border-radius: 50%;
}

.iframe-wrapper {
  width: calc(100% - 600px);
}

.ppt-title {
  /* margin-left: 30px; */
  padding-right: 20px;
  padding: 8px;
  border-bottom: 1px dashed lightgrey;
}

.ppt-item {
  width: 20%;
}

.bottom-bar {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  height: 60px;
  background: rgb(46, 100, 184);
  position: sticky;
  bottom: 20px;
  width: 45vw;
  margin: 0 10px 20px 10px;
  margin: auto;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 60px; */
  color: #ffffff;
  font-weight: bold;
  font-size: large;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.section-item {
  height: 40px;
  padding: 0 40px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background 0.3s ease, padding 0.3s ease, transform 0.3s ease;
}

.selected-section {
  background-color: #ffffff;
  border-radius: 20px;
  height: 40px;
  padding: 0 40px;
  color: rgb(46, 100, 184);
  display: flex;
  align-items: center;
  transition: background-color 0.5s ease, color 0.3s ease, padding 0.3s ease;
}

@media (max-width: 1600px) {
  .ppt-item {
    width: 25%;
  }
}

@media (max-width: 1650px) {
  .iframe-wrapper {
    width: calc(100% - 400px);
  }
}

@media (max-width: 1400px) {
  .iframe-wrapper {
    width: calc(100% - 300px);
  }
  .ppt-title {
    margin-left: 150px !important;
  }
}

@media (max-width: 1000px) {
  .app-container {
    display: flex;
    flex-direction: column;
    height: min-content;
    position: relative;
    /* background-color: #2c3e50; */
    background-color: #ffffff;
    overflow-y: hidden;
  }

  .sidebar {
    width: 100%;
  }

  .iframe-wrapper {
    width: 90%;
  }

  .ppt-title {
    margin-left: 30px !important;
  }

  .ppt-item {
    width: 80%;
  }
}

/* assessment question animation */

.assessment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: "40px";
  flex-direction: column;
  height: 60vh;
  font-size: clamp(2rem, 2.8vw, 5rem);
  font-family: Raleway;
  font-weight: bold;
}

.typewriter {
  width: 30vw;
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 4px solid #212121; */
  animation: cursor 0.5s step-start infinite, text 3s steps(18);
}

@keyframes cursor {
  0%,
  100% {
    border-color: #212121;
  }
}

@keyframes text {
  0% {
    width: 0;
  }
  100% {
    width: 21.5ch;
  }
}

/* loader animation */

.loaderWrapper {
  height: 90px;
  overflow: hidden;
}

.loaderInner {
  position: relative;
  animation-name: languageSwitch;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-delay: 2.4s;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: baseline;
  width: auto;
  width: 190px;
  height: 90px;
}

.letter {
  display: inline-block;
  font-family: sans-serif;
  font-size: 52px;
  font-weight: 900;
  color: black;
  animation-name: letterAppears;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  width: 1px;
  visibility: hidden;
  animation-delay: 1.5s;
}

.letterO {
  background-color: white;
  height: 40px;
  border-radius: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  animation-name: loader;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  border: 10px solid black;
}
.welcome {
  font-family: sans-serif;
  font-size: 52px;
  font-weight: 900;
  color: black;
  height: 90px;
  display: flex;
  align-items: center;
}

/* animation keyframes */

@keyframes loader {
  0% {
    width: 1px;
  }
  60% {
    background-color: black;
    width: 100%;
  }
  90% {
    background-color: white;
  }
  100% {
    background-color: white;
    width: 100%;
  }
}

@keyframes letterAppears {
  0% {
    width: 1px;
    transform: rotate(15deg);
  }
  100% {
    width: 100%;
    transform: rotate(0);
    visibility: visible;
  }
}

@keyframes languageSwitch {
  0% {
    bottom: 0;
  }
  15% {
    bottom: 0;
  }
  20% {
    bottom: 90px;
  }
  35% {
    bottom: 90px;
  }
  40% {
    bottom: 180px;
  }
  55% {
    bottom: 180px;
  }
  60% {
    bottom: 270px;
  }
  75% {
    bottom: 270px;
  }
  80% {
    bottom: 360px;
  }
  95% {
    bottom: 360px;
  }
  100% {
    bottom: 450px;
  }
}

.report-item {
  display: inline-block;
    /* background-color: lightblue; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    color: black;
    padding: 15px 25px;
    text-align: center;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.graph-container {
  display: inline-block;
    /* background-color: lightblue; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    color: black;
    padding: 15px 25px;
    text-align: center;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.report-item:hover {
  transform: scale(1.1); /* Slightly increases the size of the element */
}



#classDropdown {
  width: 150px;
  padding: 8px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: border-color 0.3s ease;
}

#classDropdown:hover {
  border-color: #007BFF; /* Change to a blue border on hover */
}

#classDropdown:focus {
  outline: none;
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.date-input {
  width: 100%;
  max-width: 150px;
  padding: 7px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.date-input:focus {
  border-color: #5b9dd9;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.user-activity {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    color: black;
    padding: 15px 25px;
    transition: transform 0.3s ease-in-out;
}

.user-activity:hover {
  transform: scale(1.1); /* Slightly increases the size of the element */
}

.intranet-item {
     /* display: inline-block; */
      /* background-color: lightblue; */
      /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
      /* color: black; */
      /* padding: 15px 25px; */
      /* text-align: center; */
      /* border-radius: 5px; */
      
}

.intranet-item:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .report-item {
    width: 100%; /* Make items take full width on small screens */
    margin-left: 0; /* Remove left margin */
  }

  .report-item img {
    width: 50%; /* Reduce image size */
    height: auto;
  }

  div[style*="width: 70vw"] {
    width: 100%; /* Adjust the main container width */
  }

  div[style*="width: 20vw"] {
    width: 100%; /* Adjust the sidebar width */
    margin-top: 20px; /* Add some spacing between the sections */
  }

  .report-item h4 {
    font-size: 16px; /* Adjust font size on small screens */
  }

  .report-item span {
    font-size: 20px; /* Adjust the span size */
  }
}

@media (max-width: 480px) {
  .report-item {
    width: 100%; /* Full width for very small screens */
    margin-left: 0;
  }

  .report-item img {
    width: 40%; /* Reduce image size even more */
    height: auto;
  }

  div[style*="width: 70vw"] {
    width: 100%;
  }

  div[style*="width: 20vw"] {
    width: 100%;
    margin-top: 20px;
  }

  .report-item h4 {
    font-size: 14px; /* Make text smaller */
  }

  .report-item span {
    font-size: 18px; /* Make text smaller */
  }
}
