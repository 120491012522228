.large-font{
    font-size:xxx-large;
  }
  .top-20{
    position:relative;
    top:20vh;
  }
  ion-icon.active{
    animation:like 0.5s 1;
    fill:red;
    stroke:none;
  }
  ion-icon{
    fill:transparent;
    stroke:black;
    stroke-width:30;
    transition:all 0.5s;
  }
  
  
  @-webkit-keyframes like {
    0%   { transform: scale(1); }
    90%   { transform: scale(1.2); }
    100% { transform: scale(1.1); }
  }

  .feedback-button {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    transform-origin: right center;
    background-color: #001f8d;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
  }