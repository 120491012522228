/* scrollbar.css */
.scrollbar-container {
  /* Set default height, can override in specific components */
  overflow-y: auto;
  padding-right: 8px; /* Reserve space for scrollbar */
  box-sizing: content-box; /* Ensure padding doesn't interfere with layout */
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: transparent transparent; /* Initially invisible */
}

.scrollbar-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollbar-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Initially transparent */
  border-radius: 4px;
}

.scrollbar-container::-webkit-scrollbar-track {
  background-color: transparent; /* Track is invisible */
}

.scrollbar-container:hover {
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Thumb color on hover */
}

.scrollbar-container:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Color of the scrollbar thumb */
}

.scrollbar-container:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Thumb color on hover */
}
